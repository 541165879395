import { useEffect } from 'react'
import { setCookie } from 'cookies-next'
import { GetServerSideProps } from 'next'
import { slugs } from '@/constants'
import { Durations } from '@/constants/durations'
import { paths } from '@/constants/paths'
import { RegionContextProvider } from '@/contexts/RegionContext'
import { AppFeatures } from '@/experimentation'
import { runExperimentOnServer } from '@/experimentation/server'
import { getBasicPageMetaData, Seo } from '@/layout/Seo'
import { DefaultLayout, NextPageWithLayout } from '@/layouts'
import { useLegacyEffect } from '@/molecules/utils'
import { GuildGeogatedBenefitsPrompt } from '@/organisms/GuildGeogatedBenefitsPrompt'
import { MarketingVideoPopup } from '@/organisms/MarketingVideoPopup'
import { getServerSideClient } from '@/services/ApolloClient'
import { ComingSoonNode, getComingSoonTab } from '@/services/ComingSoonService'
import { CatalogTitle, fetchTitle } from '@/services/ContentCatalog'
import { contentfulFaqLocations, getFaqsForProjectByLocation } from '@/services/FaqService'
import { getGuildMovieTicketsReleases } from '@/services/GuildMovieTicketReleaseService'
import { getGuildPromotion, Promotion } from '@/services/GuildPromotionService'
import {
  getGuildTheatricalReleases,
  GetGuildTheatricalReleasesResult,
} from '@/services/GuildReleasesService/TheatricalReleaseService'
import {
  getGuildSignupPageBySlug,
  GuildEarlyAccessSectionModel,
  GuildSignupPageData,
} from '@/services/GuildSignupPageService'
import {
  GuildProjectSlugs,
  GuildSignupEmphasis,
  GuildSignupLayout,
} from '@/services/GuildSignupPageService/GuildSignupPageTypes'
import { isGuildSignupSimpleLayout } from '@/services/GuildSignupPageService/validations'
import { getNowStreamingProjects, NowStreamingContent } from '@/services/NowStreamingService'
import { getGuildSale, GuildSale } from '@/services/SaleService'
import { Faq, GuildSignupExplainerLayout, GuildTestimonialSection } from '@/types/codegen-contentful'
import { getLocaleFromParams } from '@/utils/LocaleUtil'
import { useSafeTrack } from '@/utils/analytics'
import { setSessionProperty } from '@/utils/analytics/SessionAnalytics'
import { useStickyAttribution } from '@/utils/analytics/StickyAttribution'
import { getServerSidePropsErrorHandler, getUrlFromThisFilePath } from '@/utils/nextUtils/nextErrorHandlers'
import { omitUndefineds } from '@/utils/object'
import { useTranslate } from '@/utils/translate/translate-client'
import { loadTranslations } from '@/utils/translate/translate-server'
import { getUserGuildStatus, getUserUuidFromJwt, GuildStatus } from '@/utils/users/usersServer'
import { GuildMovieTicketRelease } from '@/views/GuildDashboardView/GuildMovieTicketsPanel/types'
import { GuildSignupView } from '@/views/GuildSignupView'
import { GuildSignupContextProvider } from '@/views/GuildSignupView/GuildSignupContext'
import { getGuildApprovedTorchPosters } from '@/views/GuildSignupView/GuildSignupContext/GuildSignupContext'

interface GuildSignupProps {
  catalogTitle?: CatalogTitle
  emphasis: GuildSignupEmphasis
  faqs: Faq[]
  guildMemberCta: string
  guildMemberHref: string
  guildApprovedTorchPosters: string[]
  guildExplainerLayout?: GuildSignupExplainerLayout | null
  layout: GuildSignupLayout
  locale: string
  projectSlugs: GuildProjectSlugs
  promotion: Promotion | null
  sale: GuildSale | null
  slug: string
  theatricalReleases: GetGuildTheatricalReleasesResult
  votingPhoneImageUrl?: string | null
  guildTestimonialSection?: GuildTestimonialSection
  guildEarlyAccessSection?: GuildEarlyAccessSectionModel
  movieTicketReleases: GuildMovieTicketRelease[]
  hasUser: boolean
  logo?: {
    src: string
    alt: string
    className?: string
  }
  upcomingProjects: ComingSoonNode[] | null
  nowStreamingProjects: NowStreamingContent[] | null
  experiments: Record<keyof AppFeatures, unknown>
}

export const GuildSignupPage: NextPageWithLayout<GuildSignupProps> = ({
  catalogTitle,
  emphasis,
  faqs,
  guildMemberCta,
  guildMemberHref,
  guildApprovedTorchPosters,
  guildExplainerLayout,
  layout,
  locale,
  projectSlugs,
  promotion,
  sale,
  slug,
  theatricalReleases,
  votingPhoneImageUrl,
  guildTestimonialSection,
  guildEarlyAccessSection,
  movieTicketReleases,
  hasUser,
  logo,
  upcomingProjects,
  nowStreamingProjects,
  experiments,
}) => {
  const { t } = useTranslate('guild')
  const track = useSafeTrack()

  useLegacyEffect(() => {
    track('Guild Product Page Viewed', { pageContext: emphasis, slug })
    setCookie('guildFocus', slug, { maxAge: Durations.ONE_YEAR_IN_SECONDS })
  }, [track, slug, emphasis])

  useEffect(() => {
    const guildProjectSlugs = projectSlugs?.projectSlugs?.join(',')
    setSessionProperty('pageSlug', slug)
    setSessionProperty('projectSlugs', guildProjectSlugs)
  }, [slug, projectSlugs])

  useStickyAttribution('guild-attribution')

  return (
    <div className={isGuildSignupSimpleLayout(layout) ? 'bg-white' : 'bg-core-gray-950'}>
      <Seo
        {...getBasicPageMetaData({
          cloudinaryImagePath: 'v1717006540/open-graph-240228.jpg',
          cloudinaryTransformation: 'b_rgb:000000',
          description: t(
            'angelStudiosGuildJoinInfoMetaDescriptionV2',
            'As an Angel Guild member, you can vote on upcoming shows, receive free tickets to every theatrical release, gain early access to shows, receive merchandise discounts, and continue to amplify light.',
          ),
          locale,
          path: `${paths.guild.join}/${slug}`,
          title: t('angelStudiosGuildEarlyAccessMetaTitleV2', 'Angel Guild Membership | Angel Studios'),
        })}
      />
      <GuildSignupContextProvider
        catalogTitle={catalogTitle}
        guildTestimonialSection={guildTestimonialSection}
        guildEarlyAccessSection={guildEarlyAccessSection}
        emphasis={emphasis}
        guildApprovedTorchPosters={guildApprovedTorchPosters}
        guildExplainerLayout={guildExplainerLayout}
        guildFaqs={faqs}
        guildMemberCta={guildMemberCta}
        guildMemberHref={guildMemberHref}
        layout={layout}
        projectSlugs={projectSlugs}
        promotion={promotion}
        sale={sale}
        slug={slug}
        theatricalReleases={theatricalReleases}
        votingPhoneImageUrl={votingPhoneImageUrl}
        movieTicketReleases={movieTicketReleases}
        logo={logo}
        upcomingProjects={upcomingProjects}
        nowStreamingProjects={nowStreamingProjects}
        experiments={experiments}
      >
        <GuildSignupView hasUser={hasUser} />
      </GuildSignupContextProvider>
      <MarketingVideoPopup ctaHref={'#plan-selection'} ctaText={t('joinTheGuild', 'Join the Guild')} />
      <RegionContextProvider>
        <GuildGeogatedBenefitsPrompt />
      </RegionContextProvider>
    </div>
  )
}

function shouldRedirectToDashboard(guildStatus: GuildStatus | null, emphasis: string) {
  if (!guildStatus) return false
  return (
    guildStatus &&
    ((emphasis === 'tickets' && guildStatus.guildRoles?.hasFreeTickets) ||
      (emphasis === 'early-access' && guildStatus.guildRoles?.hasEarlyAccess))
  )
}

export const getServerSideProps: GetServerSideProps = async ({ req, res, preview = false, params, query }) =>
  getServerSidePropsErrorHandler({ requestUrl: getUrlFromThisFilePath(params) }, async () => {
    const slug = params?.slug as string
    const locale = getLocaleFromParams(params)
    const pageData = await getGuildSignupPageBySlug({ slug, opts: { locale, preview } })
    const client = getServerSideClient({ req, res })

    if (!pageData) {
      return {
        redirect: {
          destination: paths.guild.join,
          permanent: false,
        },
      }
    }

    if (pageData.redirectTo) {
      return {
        redirect: {
          destination: pageData.redirectTo,
          permanent: false,
        },
      }
    }

    const uuid = getUserUuidFromJwt(req, res) ?? null
    const guildStatus = !uuid ? null : await getUserGuildStatus(uuid)
    const emphasis = pageData.emphasis

    if (shouldRedirectToDashboard(guildStatus, emphasis)) {
      return {
        redirect: {
          destination: pageData.guildMemberHref ?? `/guild#${emphasis}`,
          permanent: false,
        },
      }
    }

    const faqs = await getFaqsForProjectByLocation({
      location: contentfulFaqLocations.guild,
      opts: { locale, preview },
      project: slugs.angelStudios,
    })
    const theatricalReleases = await getGuildTheatricalReleases()
    const guildApprovedTorchPosters = await getGuildApprovedTorchPosters()

    const isSupportLink = query.special === 'last-chance'
    const promotion = await getGuildPromotion({ opts: { locale, preview }, isSupportLink, federationClient: client })
    const sale = await getGuildSale({ opts: { preview, locale }, federationClient: client })
    const guildExplainerLayout = getExplainerLayout(pageData)
    const guildEarlyAccessSection = getEarlyAccessSection(pageData)
    const guildTestimonialSection = getTestimonialSection(pageData)
    const movieTicketReleases = await getGuildMovieTicketsReleases({ locale, preview })

    const catalogTitle = await fetchTitle<CatalogTitle>(locale, pageData?.project?.contentCatalogId)
    const layout = pageData.layout

    const upcomingProjects = await getComingSoonTab()
    const nowStreamingProjects = await getNowStreamingProjects()
    const experiments = {} as Record<keyof AppFeatures, unknown>

    experiments['guild-signup-hide-icons-above-fold'] = await runExperimentOnServer(
      'guild-signup-hide-icons-above-fold',
      false,
      req,
      res,
    )

    if (upcomingProjects?.length && nowStreamingProjects?.length) {
      experiments['guild-signup-content-rails'] = await runExperimentOnServer(
        'guild-signup-content-rails',
        false,
        req,
        res,
      )
    }

    return {
      props: {
        catalogTitle: omitUndefineds(catalogTitle),
        emphasis,
        faqs,
        guildApprovedTorchPosters,
        guildExplainerLayout,
        guildMemberCta: pageData.guildMemberCta,
        guildMemberHref: pageData.guildMemberHref,
        layout,
        locale,
        projectSlugs: pageData.projectSlugs,
        promotion,
        sale,
        slug,
        theatricalReleases,
        votingPhoneImageUrl: pageData.votingPhoneImageUrl ?? null,
        logo: pageData?.project?.logo ?? null,
        guildTestimonialSection,
        guildEarlyAccessSection,
        movieTicketReleases,
        hasUser: Boolean(uuid),
        upcomingProjects,
        nowStreamingProjects,
        experiments,
        isDarkMode: layout?.__typename !== 'GuildSignupSimpleLayout',
        ...(await loadTranslations(locale, ['common', 'join-the-guild', 'guild', 'guild-benefits', 'app-promo'])),
      },
    }
  })

GuildSignupPage.getLayout = (page, pageProps) => {
  return <DefaultLayout isDarkMode={Boolean(pageProps?.isDarkMode)}>{page}</DefaultLayout>
}

export default GuildSignupPage

function getExplainerLayout(pageData: GuildSignupPageData) {
  return pageData.explainerLayout ?? null
}

function getEarlyAccessSection(pageData: GuildSignupPageData) {
  return pageData.guildEarlyAccessSection ?? null
}

function getTestimonialSection(pageData: GuildSignupPageData) {
  return pageData.guildTestimonialSection ?? null
}
